import { ArticlePaywallFormTheme } from '@/components/ArticlePaywall/Form/theme';
import { DialogTheme } from '@/components/Dialog/theme';
import { KlarnaPaymentTheme } from '@/components/KlarnaPayment/theme';
import { ArticlePaywallSuccessfulPaymentTheme } from '@/components/standalone/ArticlePaywall/SuccessfulPayment/theme';
import { tw } from '@/utils/tw';

const ArticlePaywallTheme = tw.theme({
  slots: {
    base: ['not-prose', 'relative', 'md:mx-auto', 'md:pt-11', 'pb-6', 'pt-8.5', 'px-4', 'sm:px-7'],
    description: ['text-center', 'mb-3'],
    headline: ['text-center', 'mb-3'],
    logo: ['-translate-x-1/2', '-translate-y-1/2', 'absolute', 'left-1/2', 'md:h-13.5', 'md:w-13.5', 'top-0'],
    featuresList: ['flex', 'flex-col', 'gap-3', 'md:items-center', 'mb-5'],
    feature: ['flex', 'gap-1.5', 'md:text-center'],
  },
});

export default Object.assign(ArticlePaywallTheme, {
  Dialog: DialogTheme,
  Form: ArticlePaywallFormTheme,
  KlarnaPayment: KlarnaPaymentTheme,
  SuccessfulPayment: ArticlePaywallSuccessfulPaymentTheme,
});
