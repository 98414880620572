import { ArticlePaywallFormTheme } from '@/components/standalone/ArticlePaywall/Form/theme';
import { Button as StandaloneButton } from '@/components/standalone/Button';
import { TextField as StandaloneTextField } from '@/components/standalone/TextField';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: ArticlePaywallFormTheme });

const Base = $({ as: 'form', slot: 'base' });
const Button = GenericSlot({ as: StandaloneButton, theme: ArticlePaywallFormTheme.Button });
const Caption = $({ as: 'p', slot: 'caption' });
const Provider = $({ as: 'div', slot: 'provider' });
const Policy = $({ as: 'p', slot: 'policy' });
const TextField = GenericSlot({ as: StandaloneTextField, theme: ArticlePaywallFormTheme.TextField });
const Fieldset = $({ as: 'fieldset', slot: 'fieldset' });

export const ArticlePaywallForm = Object.assign(Base, {
  Button,
  Caption,
  Fieldset,
  Policy,
  Provider,
  TextField,
});
