import { ArticlePaywallSuccessfulPaymentTheme } from '@/components/ArticlePaywall/SuccessfulPayment/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: ArticlePaywallSuccessfulPaymentTheme });

const Base = $({ as: 'div', slot: 'base' });
const Headline = $({ as: 'h3', slot: 'headline' });
const Caption = $({ as: 'p', slot: 'caption' });
const Description = $({ as: 'p', slot: 'description' });
const Steps = $({ as: 'ol', slot: 'steps' });
const Step = $({ as: 'li', slot: 'step' });
const ContactInfo = $({ as: 'p', slot: 'contactInfo' });

export const SuccessfulPayment = Object.assign(Base, { Headline, Caption, Description, Steps, Step, ContactInfo });
