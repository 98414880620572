import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { SuccessfulPayment } from './SuccessfulPayment';

export interface StandaloneSuccessfulPaymentProps extends StandaloneComponentProps<typeof SuccessfulPayment> {
  headline?: ReactNode;
  caption?: ReactNode;
  description?: ReactNode;
  steps?: ReactNode[];
  contactInfo?: ReactNode;
}

export const StandaloneArticlePaywallSuccessfulPayment: StandaloneComponent<StandaloneSuccessfulPaymentProps> = ({
  headline,
  caption,
  description,
  steps,
  contactInfo,
  ...props
}) => {
  return (
    <SuccessfulPayment {...props}>
      {headline && <SuccessfulPayment.Headline>{headline}</SuccessfulPayment.Headline>}
      {caption && <SuccessfulPayment.Caption>{caption}</SuccessfulPayment.Caption>}
      {description && <SuccessfulPayment.Description>{description}</SuccessfulPayment.Description>}
      {Boolean(steps?.length) && (
        <SuccessfulPayment.Steps>
          {steps!.map((step, index) => (
            <SuccessfulPayment.Step key={index}>{step}</SuccessfulPayment.Step>
          ))}
        </SuccessfulPayment.Steps>
      )}
      {contactInfo && <SuccessfulPayment.ContactInfo>{contactInfo}</SuccessfulPayment.ContactInfo>}
    </SuccessfulPayment>
  );
};
