import { tw } from '@/utils/tw';

const SuccessfulPaymentTheme = tw.theme({
  slots: {
    base: ['mb-1.5', 'md:mb-2', 'text-center'],
    headline: ['mb-4', 'md:mb-5', 'text-center'],
    caption: ['mb-3', 'md:mb-8'],
    description: ['mb-3', 'md:mb-8', 'text-left'],
    steps: ['flex', 'flex-col', 'gap-3', 'md:gap-7', 'mb-6', 'md:mb-8', '[counter-reset:step]'],
    step: [
      'pl-4.5',
      'md:pl-6',
      'relative',
      '[counter-increment:step]',
      'before:content-[counter(step)"."]',
      'before:absolute',
      'before:left-0',
      'text-left',
    ],
    contactInfo: ['text-center'],
  },
});

export default SuccessfulPaymentTheme;
