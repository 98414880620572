import useOrderCreate from '@/hooks/useOrderCreate';
import useUserAuth from '@/hooks/useUserAuth';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { ComponentProps, ReactNode, useRef } from 'react';
import { useToggle } from 'react-use';
import { ArticlePaywall } from './ArticlePaywall';

export interface ArticlePaywallProps extends StandaloneComponentProps<typeof ArticlePaywall> {
  description?: ReactNode;
  headline?: ReactNode;
  logo?: ReactNode;
  dialog?: ComponentProps<typeof ArticlePaywall.Dialog>;
  features?: ReactNode[];
  form?: ComponentProps<typeof ArticlePaywall.Form>;
  klarnaPayment?: ComponentProps<typeof ArticlePaywall.KlarnaPayment>;
  successfulPayment?: ComponentProps<typeof ArticlePaywall.SuccessfulPayment>;
  handleSuccessPayment?: (
    ref: React.RefObject<HTMLDialogElement>,
    toggleIsPaymentSuccessful: (value: boolean) => void,
  ) => ComponentProps<typeof ArticlePaywall.KlarnaPayment>['handleSuccess'];
  handleFailurePayment?: (
    ref: React.RefObject<HTMLDialogElement>,
  ) => ComponentProps<typeof ArticlePaywall.KlarnaPayment>['handleFailure'];
}

const paymentContainerId = 'payment-container';

export const StandaloneArticlePaywall: StandaloneComponent<ArticlePaywallProps> = ({
  description,
  headline,
  logo,
  dialog,
  features,
  form,
  klarnaPayment,
  handleSuccessPayment,
  handleFailurePayment,
  successfulPayment,
  ...props
}) => {
  const { alltIdUserCode } = useUserAuth();
  const { createOrder } = useOrderCreate();
  const [isPaymentSuccessful, toggleIsPaymentSuccessful] = useToggle(false);
  const dialogRef = useRef<HTMLDialogElement | null>(null);

  return (
    <ArticlePaywall {...props}>
      {logo && <ArticlePaywall.Logo>{logo}</ArticlePaywall.Logo>}
      {headline && !isPaymentSuccessful && <ArticlePaywall.Headline>{headline}</ArticlePaywall.Headline>}
      {description && !isPaymentSuccessful && <ArticlePaywall.Description>{description}</ArticlePaywall.Description>}
      {Boolean(features?.length) && !isPaymentSuccessful && (
        <ArticlePaywall.featuresList>
          {features!.map((feature, index) => (
            <ArticlePaywall.feature key={index}>{feature}</ArticlePaywall.feature>
          ))}
        </ArticlePaywall.featuresList>
      )}
      {isPaymentSuccessful ? (
        <ArticlePaywall.SuccessfulPayment
          {...mergeProps(successfulPayment, { options: props.options?.$successfulPayment })}
        />
      ) : alltIdUserCode ? (
        <ArticlePaywall.KlarnaPayment
          button={{ content: 'Betala', options: { size: 'large' } }}
          handleSuccess={
            handleSuccessPayment
              ? handleSuccessPayment(dialogRef, toggleIsPaymentSuccessful)
              : async function (response) {
                  await createOrder(alltIdUserCode!, response.token);
                  toggleIsPaymentSuccessful(true);
                }
          }
          handleFailure={
            handleFailurePayment
              ? handleFailurePayment(dialogRef)
              : async function () {
                  dialogRef.current?.showModal();
                  // TODO: Handle failure
                }
          }
          paymentContainerId={paymentContainerId}
          {...mergeProps(
            { options: props.options?.$klarnaPayment },

            klarnaPayment,
          )}
        />
      ) : (
        form && <ArticlePaywall.Form {...mergeProps({ options: props.options?.$form }, form)} />
      )}
      {dialog && <ArticlePaywall.Dialog options={{ ref: dialogRef }} {...dialog} />}
    </ArticlePaywall>
  );
};
